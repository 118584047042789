/**
 * Hashes the input string using the SHA-256 algorithm.
 *
 * @param {string} input - The string to be hashed.
 * @return {Promise<string>} - The hashed string.
 */
export const getSHA256Hash = async (input:string) => {
    const textAsBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray
      .map((item) => item.toString(16).padStart(2, "0"))
      .join("");
    return hash;
  };