import React, { useEffect } from 'react';
import styles from './snackbar.module.css';

interface SnackbarProps {
  message: string;
  duration: number;
  onClose: () => void;
}

const Snackbar: React.FC<SnackbarProps> = ({ message, duration, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      handleClose();
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration]);

  const handleClose = () => {
    onClose();
  };

  return (
    <div className={styles.snackbar}>
      <div className={styles.message}>{message}</div>
      <div className={styles.closeButton} onClick={handleClose}>
        <span>&times;</span>
      </div>
    </div>
  );
};

export default Snackbar;
