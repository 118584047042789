/*OPENAI COST OVERVIEW 
     Modelle	     Kontext	Eingabeaufforderung (pro 1.000 Token)	Abschluss (pro 1.000 Token)
     GPT-3.5-Turbo 4K	        $0,0015	                                $0,002
     GPT-3.5-Turbo 16K	    $0,003	                                $0,004
     GPT-4	     8K	        $0,03	                                $0,06
     GPT-4	     32K	    $0,06	                                $0,12
*/
import tokenizer from 'gpt-tokenizer'

/**
 * Calculates the total number of tokens used in a conversation.
 *
 * @param {any[]} conversation - The conversation object.
 * @return {number} The total number of tokens used.
 */
export function calculateTotalTokenUsage(conversation: any) {
    if (conversation.length == 0)
        return 0;

    const chatTokens = tokenizer.encodeChat(conversation, 'gpt-3.5-turbo');
    const tokenCount = chatTokens.length;
    return tokenCount;
}

/**
 * Calculates the cost of tokens used for a request and response.
 *
 * @param {number} requestTokens - the number of tokens used for the request
 * @param {number} responseTokens - the number of tokens used for the response
 * @return {number} the total cost of tokens
 */
export function calculateTokenCosts(requestTokens: number, responseTokens: number) {
    const promptCost = (requestTokens / 1000) * 0.003;
    const responseCost = (responseTokens / 1000) * 0.004;
    
    const totalCost = promptCost + responseCost;
    
    return totalCost;
  }
