import { useRef, useState, useEffect } from "react";
import { Stack } from "@fluentui/react";
import { SquareRegular } from "@fluentui/react-icons";
import { ChatMessage, ConversationRequest, conversationApi, Citation, ChatResponse, getUserInfo, UserInfo, isAuthorized, Protocol, getConfiguration } from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import ProtocolKinds from "../../constants/protocolKinds";
import { takeProtocol } from "../../api/protocol/protocol";
import styles from "./Chat.module.css";
import Authentication from "./authentication/authentication";
import Authorization from "./authorization/authorization";
import AnswerComponent from "./answer/answer";
import CitationComponent from "./citation/citation";
import { extractTextFromFile } from "../../helper/fileExtractor/fileExtractor";
import Snackbar from "../../helper/components/snackbar/snackbar";
import { getUserFromGraph } from "../../api/graphUser/graphUser";
import { extendPrompt } from "../../helper/ai/extendPrompt";
import { getSHA256Hash } from "../../helper/hashes/sha256";
import { calculateTotalTokenUsage, calculateTokenCosts } from "../../helper/token/token";

import RhenusLogo from "../../assets/rhenus_logo.svg";

import { isSessionExpired, setupSession } from "../../helper/sessionHandler/sessionHandler";
import showSnackbarWithMessage from "../../helper/components/snackbar/snackbarManager/snackbarManager";
import { TeamsApp } from "../../services/teams/teamsService";
import Logging from "./logging/logging";
import Profile from "./profile/profile";
import { getConfig, initializeConfig } from "../../helper/configProvider/configProvider";
const teamsAppFunctions = TeamsApp();

const Chat = () => {
    /* LOADING INDICATOR */
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    /* CITATION */
    const [activeCitation, setActiveCitation] = useState<[content: string, id: string, title: string, filepath: string, url: string, metadata: string]>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    /* ANSWERS */
    const [answers, setAnswers] = useState<ChatMessage[]>([]);
    /* AUTHENTICATION */
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [currentUsername, setCurrentUsername] = useState<string>('');
    const [displayName, setDisplayName] = useState<string>('');
    /* LOGGING */
    const [loggingOption, setLoggingOption] = useState<string>('');
    const [isInGroup, setIsInGroup] = useState<boolean>(false);
    /* SIDEBAR */
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    /* SNACKBAR */
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarDuration, setSnackbarDuration] = useState(3000);
    /* QUESTION INPUT */
    const [lastQuestionRef, setLastQuestionRef] = useState<string>("");
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const abortFuncs = useRef([] as AbortController[]);
    /*FILE UPLOAD */
    const [files, setFiles] = useState<ExtendedFile[]>([]);
    /* MOBILE VIEW */
    const [isMobileView, setIsMobileView] = useState(false);
    /* TOKEN USAGE */
    const [tokens, setTokens] = useState(0);
    /* SETUP COMPLETED */
    const [setupDone, setSetupDone] = useState<boolean>(false);
    /* CONVERSATION SESSION */
    const [session, setSession] = useState<Session>({
        expired: false,
        started: new Date(),
        expires: new Date(),
        sessionId: ''
    });
    /* FOLLOW UP QUESTIONS*/
    const [followUpQuestions, setFollowUpQuestions] = useState<string[]>([]);
    /* TEAMS App */
    const [isTeamsApp, setIsTeamsApp] = useState(false);
    /* EXTENDED PROMPT BY ME ENDPOINT */
    const [extendedPromptByMe, setExtendedPromptByMe] = useState("");
    /* EXTENDED PROMPT BY FILE ENDPOINT */
    const [extendedPromptByFiles, setExtendedPromptByFiles] = useState("");

    /* INITIALIZE USER INFO */
    let userInfo: UserInfo;


    const getUserInfoList = async (isTeamsApp = false) => {
        try {
            const userInfoList = await getUserInfo(isTeamsApp);

            if (userInfoList && userInfoList.length > 0) {
                setShowAuthMessage(false);
                userInfo = userInfoList[0];
                setCurrentUsername(userInfoList[0].user_id || "");
                getDisplayName(userInfoList[0]);
            } else {
                setShowAuthMessage(true);
            }
        } catch (error) {
            console.error("Error while getting user info:", error);
        }
    };

    const getDisplayName = (userInfo: UserInfo) => {
        if (userInfo.user_claims) {
            userInfo.user_claims.forEach(element => {
                if (element.typ == "name")
                    setDisplayName(element.val);
            });
        }
    }
    /**
     * Checks if the user is authorized.
     *
     * @return {Promise<void>} - A promise that resolves once the user's authorization status has been determined.
     */
    const isUserAuthorized = async () => {

        if (userInfo?.access_token) {
            const isUserAuthorized = await isAuthorized(userInfo)
            setIsInGroup(isUserAuthorized);
        }
        else {
            console.log("No access_token found");
        }
    }
    /**
     * Extracts and cleans up follow-up questions from the given content.
     *
     * @param {string} content - The content from which to extract follow-up questions.
     * @return {string} The cleaned up content after removing the follow-up questions.
     */
    const extractAndCleanUpFollowUpQuestions = (content: string) => {
        const followUpRegex = /\{-([^{}]+)-\}/g;
        const followUpQuestions = [];
        let match;

        while ((match = followUpRegex.exec(content))) {
            followUpQuestions.push(match[1]);
        }
        content = content.replace(followUpRegex, '')
        setFollowUpQuestions(followUpQuestions);
        return content;
    }
    const splitPayload = (content: any) => {
        const fullPayloadString = content.reduce((acc: any, item: { content: any; }) => {
            return acc + item.content;
        }, "");

        return fullPayloadString;
    }
    /**
     * Makes an API request based on the given question.
     *
     * @param {string} question - The question to be sent in the API request.
     * @param {boolean} file - Indicates if the question is a file.
     * @param {string} fileName - The name of the file being summarized.
     * @return {Promise<void>} - A Promise that resolves after the API request is completed.
     */
    const makeApiRequest = async (question: string, file?: boolean, fileName?: string) => {
        if (session && isSessionExpired(session)) {
            showSnackbarWithMessage("Your session has expired. Refreshing conversation...", 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
            setTimeout(() => {
                clearChat();
                setupSession(setSession);
                showSnackbarWithMessage("Session refreshed!", 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
            }, 5000);
            return;
        }

        setLastQuestionRef(question)
        let protocolize: boolean = false;
        let protocol: Protocol = {
            name: '',
            prompt: '',
            answer: '',
            date: new Date(),
            responseTime: 0,
            tokenEstimate: 0,
            sessionId: '',
            fileUpload: false,
            estimatedCost: 0
        };

        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            role: "user",
            content: question
        };

        const request: ConversationRequest = {
            messages: [...answers.filter((answer) => answer.role !== "error"), userMessage],
            extendedPromptByMeEndpoint: extendedPromptByMe,
            extendedPromptByFilesEndpoint: extendedPromptByFiles
        };
        let result = {} as ChatResponse;
        try {
            const startTime = new Date();
            const response = await conversationApi(request, abortController.signal);
            const endTime = new Date();
            const responseTime = (endTime.getTime() - startTime.getTime()) / 1000;
            let currentAnswer = '';

            if (response?.body) {
                const reader = response.body.getReader();

                let runningText = "";
                let isFollowUpMode = false;

                while (true) {
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
    
                    setShowLoadingMessage(false);

                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);

                            //COGNITIVE SEARCH DEACTIVATED - CONTENT WITH SYSTEM PROMPT (which includes {-)
                            if(result['choices'][0]['messages'][0]['role'] == 'assistant' && result['choices'][0]['messages'][0]['content'].includes("{-") && getConfig().COGNITIVE_SEARCH_STATE == null)
                                isFollowUpMode = true;

                            //COGNITIVE SEARCH ACTIVATED - CONTENT WITHOUT SYSTEM PROMPT
                            else if(runningText.includes("{-") && getConfig().COGNITIVE_SEARCH_STATE != null)
                                isFollowUpMode = true;
                            
                            runningText = "";
                            
                            if(!isFollowUpMode)
                                setAnswers([...answers, userMessage, ...result.choices[0].messages]);

                            isFollowUpMode = false;
                        }
                        catch (e) { }
                    });
                }
                /* CONCATINTATION OF ALL PROMPTS AND USER QUESTIONS */
                let fullPayload = result.full_payload;
                const fullPayloadString = splitPayload(fullPayload);

                /*CLEAN UP ANSWER AND EXTRACT THE FOLLOW UP QUESTION - WITH DATA - WITHOUT DATA*/
                if (result.choices[0].messages[0].role == 'tool' && result.choices[0].messages[0].content != '{\"citations\": [], \"intent\": \"[]\", \"search_intent\": \"[]\"}"}]}]') {
                    result.choices[0].messages[1].content = extractAndCleanUpFollowUpQuestions(result.choices[0].messages[1].content)
                    currentAnswer = result.choices[0].messages[1].content;
                }
                else {
                    result.choices[0].messages[0].content = extractAndCleanUpFollowUpQuestions(result.choices[0].messages[0].content)
                    currentAnswer = result.choices[0].messages[0].content;
                }
                let totalTokensUsed = result.usage.total_tokens;
                let requestTokens = result.usage.request_tokens;
                let responseTokens = result.usage.response_tokens;

                const defaultProtocol = {
                    sessionId: session.sessionId,
                    fileUpload: files.length > 0,
                    tokenEstimate: totalTokensUsed,
                    estimatedCost: calculateTokenCosts(requestTokens, responseTokens),
                };

                if (loggingOption == ProtocolKinds.ANONYM) {
                    const salt = 'Mn19KA2sklFd';
                    let hash = await getSHA256Hash(currentUsername + salt);

                    protocol = {
                        name: hash || 'DEBUG',
                        prompt: userMessage.content,
                        answer: currentAnswer,
                        date: new Date(),
                        responseTime: responseTime,
                        ...defaultProtocol,
                    };

                    protocolize = true;
                    userMessage.protocolize = protocolize;

                } else if (loggingOption == ProtocolKinds.PERSONAL) {
                    protocol = {
                        name: currentUsername || 'DEBUG',
                        prompt: userMessage.content,
                        answer: currentAnswer,
                        date: new Date(),
                        payload: fullPayloadString,
                        responseTime: responseTime,
                        ...defaultProtocol,
                    };

                    protocolize = true;
                    userMessage.protocolize = protocolize;

                } else {
                    protocol = {
                        name: 'NO_PROTOCOL',
                        prompt: 'NO_PROTOCOL',
                        answer: 'NO_PROTOCOL',
                        responseTime: responseTime,
                        ...defaultProtocol,
                    };

                    takeProtocol(protocol);
                }
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                setAnswers([...answers, userMessage, {
                    role: "error",
                    content: errorMessage
                }]);
            } else {
                setAnswers([...answers, userMessage]);
            }
        } finally {
            let protocolId: number;

            if (protocolize) {
                protocolId = await takeProtocol(protocol);
                userMessage.protocolId = protocolId;
            }

            const updatedAnswers = [...answers, userMessage, ...result.choices[0].messages];
            setAnswers(updatedAnswers);

            let tokenAmount = calculateTotalTokenUsage(updatedAnswers);
            setTokens(tokens + tokenAmount);

            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
        }

    };

    const clearChat = async () => {
        setFiles([]);
        setLastQuestionRef("");
        setFollowUpQuestions([])
        setActiveCitation(undefined);
        setAnswers([]);
        setTokens(0);
        setExtendedPromptByFiles("");
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    const handleSliderChange = (value: string) => {
        setLoggingOption(value);
    };

    const fetchUser = async (inProduction = true, isTeamsApp = false) => {

        if (!inProduction) {
            setIsInGroup(true);
            setShowAuthMessage(false);
            return;
        }

        await getUserInfoList(isTeamsApp);
        await isUserAuthorized();
        await getUserFromGraph(userInfo.access_token).then(data => {
            setExtendedPromptByMe(extendPrompt(data));
        });
    };

    const configurePrompt = async (uploadedFiles: ExtendedFile[]) => {
        let result: ExtendedFile[] = [];
        let fullPrompt: string;
        if (uploadedFiles.length >= 0) {
            ({ result, fullPrompt } = await extractTextFromFile(uploadedFiles));
            setExtendedPromptByFiles(fullPrompt);
        }

        return result;
    }

    const setupChat = async () => {

        //If current platform is not teams
        if (!teamsAppFunctions.checkIfTeams()) {
            fetchUser();
        }
        //If current platform is teams
        else {
            setIsTeamsApp(true)
            fetchUser(true, true)
        }
        setupSession(setSession);

        setIsMobileView(window.innerWidth <= 768);
        setSetupDone(true);
    };

    useEffect(() => {
        const fetchData = async () => {
    
            await initializeConfig();

            if (window.innerWidth > 768)
                setIsSidebarOpen(true);

            if (!setupDone) {
                await teamsAppFunctions.initialize();
                setupChat();
            }

            configurePrompt(files);
        };

        fetchData();
    }, [files]);

    

    useEffect(() => chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" }), [showLoadingMessage]);

    const onShowCitation = (citation: Citation) => {
        setActiveCitation([citation.content, citation.id, citation.title ?? "", citation.filepath ?? "", citation.url ?? "", ""]);
        setIsCitationPanelOpen(true);
    };

    return (
        <div className={styles.container} role="main">
            <div className={`${styles.content} ${isSidebarOpen ? styles.contentOpen : ''}`}>
                {
                    showAuthMessage ? (
                        <Authentication />
                    ) : (<>
                        <img
                            src={RhenusLogo}
                            className={styles.headerIcon}
                            aria-hidden="true"
                        />
                        {isInGroup ? (
                            <Stack horizontal className={styles.chatRoot}>
                                <Profile isMobileView={isMobileView} displayName={displayName} isTeamsApp={isTeamsApp} />

                                <div className={styles.chatContainer}>
                                    {!lastQuestionRef ? (
                                            <Stack className={styles.chatEmptyState}>
                                                <h1 className={styles.chatEmptyStateTitle}>Start chatting</h1>
                                                <Logging handleSliderChange={handleSliderChange} /> 
                                            </Stack>
                                    ) : (
                                        <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                            {answers.map((answer, index) => (
                                                <AnswerComponent answer={answer} answers={answers} index={index} onShowCitation={onShowCitation} />
                                            ))}
                                            {showLoadingMessage && (
                                                <>
                                                    <div className={styles.chatMessageUser}>
                                                        <div className={styles.chatMessageUserMessage}>
                                                            {lastQuestionRef}
                                                        </div>
                                                    </div>
                                                    <div className={styles.chatMessageGpt}>
                                                        <Answer
                                                            answer={{
                                                                answer: "Generating answer...",
                                                                citations: []
                                                            }}
                                                            onCitationClicked={() => null}
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            <div ref={chatMessageStreamEnd} />
                                        </div>
                                    )}
                                    <div className={styles.userActionContainer}>
                                        {!isLoading && followUpQuestions.length > 0 && (
                                            <div className={styles.followUpQuestionsContainer}>
                                                {followUpQuestions.map((question, index) => (
                                                    <div key={index} className={styles.followUpQuestion} onClick={() => makeApiRequest(question)}>
                                                        {question}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        <Stack horizontal className={styles.chatInput}>
                                            {isLoading && (
                                                <Stack
                                                    horizontal
                                                    className={styles.stopGeneratingContainer}
                                                    role="button"
                                                    aria-label="Stop generating"
                                                    tabIndex={0}
                                                    onClick={stopGenerating}
                                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? stopGenerating() : null}
                                                >
                                                    <SquareRegular className={styles.stopGeneratingIcon} aria-hidden="true" />
                                                    <span className={styles.stopGeneratingText} aria-hidden="true">Stop generating</span>
                                                </Stack>
                                            )}
                                            
                                            <QuestionInput
                                                clearOnSend
                                                placeholder="Type a new question..."
                                                disabled={isLoading}
                                                onSend={question => makeApiRequest(question)}
                                                clearChat={clearChat} 
                                                files={files}
                                                setFiles={setFiles}
                                                setShowSnackbar={setShowSnackbar}
                                                snackbarMessage={snackbarMessage}
                                                setSnackbarMessage={setSnackbarMessage}
                                                snackbarDuration={snackbarDuration}
                                                setSnackbarDuration={setSnackbarDuration}
                                                configurePrompt={configurePrompt}
                                                isMobileView={isMobileView}
                                                setExtendedPromptByFiles={setExtendedPromptByFiles}
                                            />

                                        </Stack>
                                    </div>
                                </div>
                                {answers.length > 0 && isCitationPanelOpen && activeCitation && (
                                    <CitationComponent activeCitation={activeCitation} setIsCitationPanelOpen={setIsCitationPanelOpen} />
                                )}
                                {showSnackbar && (
                                    <Snackbar message={snackbarMessage} duration={snackbarDuration} onClose={() => setShowSnackbar(false)} />
                                )}
                            </Stack>
                        )
                            : (
                                <Authorization />
                            )}
                    </>
                    )}
            </div>
        </div>
    );
};

export default Chat;
