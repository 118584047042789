import React, { useState, useEffect } from 'react';
import './Slider.css';

interface SliderProps {
  values: string[];
  tooltips: string[];
  steps: number;
  defaultValue?: string;
  onChange?: (value: string) => void;
}

const Slider: React.FC<SliderProps> = ({ values, tooltips, steps, defaultValue, onChange }) => {
  const [value, setValue] = useState(defaultValue || values[0]);

  useEffect(() => {
    if (onChange) {
      onChange(value);
    }
  }, [value, onChange]);

  const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = values[parseInt(event.target.value)];
    setValue(newValue);
  };

  const handleButtonClick = (newValue: string) => {
    setValue(newValue);
  };

  return (
    <div className="slider-container">
      <div id="slider-header">
      </div>
      <input
        type="range"
        min={0}
        max={values.length - 1}
        step={1}
        value={values.indexOf(value)}
        onChange={handleSliderChange}
      />
      <div className="labels">
        {values.map((val, index) => (
          <span key={index} className="tooltip" data-tooltip={tooltips[index]}>
            <button
              onClick={() => handleButtonClick(val)}
              className={`button-style ${val === value ? 'selected' : ''}`} 
            >
              {val}
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default Slider;
