import { app } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { getConfig, initializeConfig } from '../../helper/configProvider/configProvider';
import generateGuid from '../../helper/guid/guidHandler';

const SignInStart = () => {
  useEffect(() => {
    const initializeApp = async () => {

      await initializeConfig();
      await app.initialize();
      const context = await app.getContext();
      const state = generateGuid();
      localStorage.setItem("simple.state", state);

      const queryParams: Record<string, string> = {
        client_id: getConfig().AZURE_CLIENT_ID,
        response_type: "token",
        redirect_uri: `${window.location.origin}/auth/sign-in`,
        scope: "openid profile offline_access",
        response_mode: "fragment",
        state,
        nonce: generateGuid(),
        login_hint: context.user?.loginHint || ""
      };

      const queryString = new URLSearchParams(queryParams).toString();
      window.location.assign(`https://login.microsoftonline.com/${context.user?.tenant?.id}/oauth2/v2.0/authorize?${queryString}`);
    };

    initializeApp();
  }, []);

  return (
    <>
      Redirecting to Microsoft login...
    </>
  );
};

export default SignInStart;