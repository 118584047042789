interface CitationViewProps {
    citationData: string,
    source: string,
}
const CitationView = ({ citationData, source }: CitationViewProps) => {
    const citationLines = citationData.split('\n');

    return (
        <div>
            <h2>Citation Information</h2>
                {citationLines.map((line, index) => (
                    <div key={index}>{line}</div>
                ))}
            <p>
                Source: {source.includes('https') || source.includes('https') ? <a target="_blank" href={source}>{source}</a> : source}
            </p>
        </div>
    );
};

export default CitationView;
