import { maximumPagesReached } from "../helper/pageCounter/pageCounter";

/**
 * Extracts text from a given txt file.
 *
 * @param {ArrayBuffer} arrayBuffer - The array buffer containing the txt file.
 * @return {Promise<string>} The extracted text from the txt file.
 */
export async function extractTextFromTxtFile(arrayBuffer: ArrayBuffer): Promise<string> {

    let text = new TextDecoder().decode(new Uint8Array(arrayBuffer));
    text = text.replaceAll('\n', ' ');
    const words = text.trim().split(/\s+/);
    const wordCount = words.length;

    if(maximumPagesReached(wordCount))
            throw { status: 502, message: "The text file is too big!" };
        
    return text;
}
