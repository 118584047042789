import { Stack } from '@fluentui/react';
import styles from '../Chat.module.css';
import CitationView from '../citationView/citationView';
import Delete from '../../../assets/file_overview/delete.svg';
interface CitationProps {
    activeCitation: [content: string, id: string, title: string, filepath: string, url: string, metadata: string],
    setIsCitationPanelOpen: React.Dispatch<React.SetStateAction<boolean>>
}

function CitationComponent({activeCitation, setIsCitationPanelOpen}: CitationProps) {
    return (
        <Stack.Item className={styles.citationPanel} tabIndex={0} role="tabpanel" aria-label="Citations Panel">
            <Stack aria-label="Citations Panel Header Container" horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                <img aria-label="Close citations panel" onClick={() => setIsCitationPanelOpen(false)} src={Delete} />
            </Stack>
            <h5 className={styles.citationPanelTitle} tabIndex={0}>{activeCitation[2]}</h5>
            <div tabIndex={0}>
                 <CitationView citationData={activeCitation[0]} source={activeCitation[4]} />
            </div>
        </Stack.Item>
    )
}

export default CitationComponent;