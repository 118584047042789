import generateGuid from "../guid/guidHandler";

/**
 * Sets up a session and updates the session state.
 *
 * @param {React.Dispatch<React.SetStateAction<Session>>} setSession - The function to update the session state.
 */
function setupSession(setSession: React.Dispatch<React.SetStateAction<Session>>){
    let session = {
        expired: false,
        started: new Date(),
        expires: new Date(new Date().getTime() + 4 * 60 * 60 * 1000),
        sessionId: generateGuid(),
    };
    setSession(session);
}

/**
 * Checks if the session is expired.
 *
 * @param {Session} session - The session object to check.
 * @return {boolean} True if the session is expired, false otherwise.
 */
function isSessionExpired(session: Session){
    try {
        if(new Date().getTime() > session.expires.getTime()){
            return true;
        }
        else {
            return false;
        }
    } catch (error) {
      console.error('Error retrieving session from localStorage:', error);
    }    
}

export { setupSession, isSessionExpired };