/**
 * Generates a random UUID.
 *
 * @return {string} A randomly generated Guid.
 */
function generateGuid(): string {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, (c) => {
        const r = crypto.getRandomValues(new Uint8Array(1))[0] & 15;
        const v = parseInt(c, 10) ^ r >> parseInt(c, 10) / 4;
        return v.toString(16);
    });
}

export default generateGuid;