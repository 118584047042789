import { GraphUser } from "../../api";

/**
 * Extends the prompt with user data and sends a POST request to the '/api/prompt/extendMe' endpoint.
 *
 * @param {GraphUser} userData - The user data to be included in the prompt.
 * @return {Promise<any>} - A promise that resolves to the response data from the server.
 */
export function extendPrompt(userData: GraphUser) {
  const prompt = `To enhance our conversation, please use the following user data:

    - Name: ${userData.displayName}
    - Job Title: ${userData.jobTitle}
    - Preferred Language: ${userData.preferredLanguage}
    - Email: ${userData.mail}
    - Phone Number: ${userData.mobilePhone}
    - Office Location: ${userData.officeLocation}
    
    Customize responses based on this data, especially considering my job title for tailored responses. If any of the data is null, leave it blank.
    When asked, provide the full user data as a list without adding any additional information.
    If I request assistance, please utilize my details to fulfill the request and avoid inventing any information not provided in this data.
    
    Example:
    User: Hello
    Assistant: Hi ${userData.displayName}, how can I help you?

    Example 2:
    User: Who am i? (or similiar question)
    Assistant: 
    - You are ${userData.displayName}. 
    - Your job title is ${userData.jobTitle}. 
    - Your preferred language is ${userData.preferredLanguage}. 
    - Your email is ${userData.mail}. 
    - Your phone number is ${userData.mobilePhone}. 
    - Your office location is ${userData.officeLocation}.
    `;
  return prompt;
}

/**
 * Extends the prompt by including the content of the specified files.
 *
 * @param {ExtendedFile[]} files - An array of ExtendedFile objects representing the files to include in the prompt.
 * @return {Promise<any>} - A promise that resolves to the response from the API call.
 */
export async function extendPromptByFiles(files: ExtendedFile[]) {

  let contentString = "";
  files.forEach((file) => {
    if (file.content != undefined && file.activated)
      contentString += file.content;
  });

  let fileNameString = "";
  files.forEach((file) => {
    if(file.content != undefined && file.activated)
        fileNameString += file.fileName + ", ";
  });
  
  let finalDocumentPrompt = 
  `Here are documents displayed as text for my following questions. Use them to answer my questions. 
   When I refer to files or similar, I am talking about the following text.
   It is very important that you say that you only know the files ${fileNameString}.

   For Example:
   User: What files do you have? (or similiar question)
   Assistant: I know the following files: ${fileNameString}.`;

  const prompt = finalDocumentPrompt + contentString;

  return prompt;
}
