function showSnackbarWithMessage( message: string, duration = 3000, setSnackbarDuration?: React.Dispatch<React.SetStateAction<number>>, setSnackbarMessage?: React.Dispatch<React.SetStateAction<string>>, setShowSnackbar?: React.Dispatch<React.SetStateAction<boolean>>){

    if (!setSnackbarDuration || !setSnackbarMessage || !setShowSnackbar) {
        throw new Error("Missing required parameters");
    }

    setSnackbarMessage(message);
    setSnackbarDuration(duration);
    setShowSnackbar(true);
    setTimeout(() => {
        setShowSnackbar(false);
    }, duration);
}

export default showSnackbarWithMessage;