
/* HELPERS */
import { extractTextFromDocx } from './extensions/docx';
import { extractTextFromExcel, extractTextFromXLS } from './extensions/xls-xlsx';
import { extractTextFromPDF } from './extensions/pdf';
import { extractTextFromTxtFile } from './extensions/text';
import { extractTextFromCSV } from './extensions/csv';
import { extendPromptByFiles } from '../ai/extendPrompt';
/*---------*/


/**
 * Extracts text from an array of files.
 *
 * @param {ExtendedFile[]} files - The array of files to extract text from.
 * @return {Promise<ExtendedFile[]>} A promise that resolves to an array of ExtendedFiles with extracted text.
 */
export function extractTextFromFile(files: ExtendedFile[]): Promise<{ result: ExtendedFile[], fullPrompt: string }> {
    return new Promise<{ result: ExtendedFile[], fullPrompt: string }>(async (resolve, reject) => {
        const result: ExtendedFile[] = [];
        // Clearing the last document from the prompt
        if (files.length == 0) {
            extendPromptByFiles(files);
            const fullPrompt = await extendPromptByFiles(files);
            resolve({ result, fullPrompt });
            return;
        }

        const filePromises = files.map(async (file, idx) => {

            let text = "";
            const reader = new FileReader();

            return new Promise<ExtendedFile>(async (resolve) => {
                reader.onload = async (event) => {
                    const arrayBuffer = event.target?.result as ArrayBuffer;
                    const fileExtension = file.properties.name.split('.').pop();
                    file.fileName = file.properties.name;
                    switch (fileExtension) {
                        case 'txt':
                            try {
                                text = await extractTextFromTxtFile(arrayBuffer);
                            } catch (error: any) {
                                file.status = error.status;
                            }
                            break;
                        case 'pdf':
                            try {
                                text = await extractTextFromPDF(arrayBuffer);
                            } catch (error: any) {
                                file.status = error.status;
                            }
                            break;
                        case 'docx':
                            try {
                                text = await extractTextFromDocx(arrayBuffer);
                            } catch (error: any) {
                                file.status = error.status;
                            }
                            break;
                        case 'xls':
                            // Currently has no limitation
                            text = await extractTextFromXLS(arrayBuffer);
                            break;
                        case 'xlsx':
                            // Currently has no limitation
                            text = await extractTextFromExcel(arrayBuffer);
                            break;
                        case 'csv':
                            // Currently has no limitation
                            let csv = new TextDecoder().decode(new Uint8Array(arrayBuffer));
                            text = extractTextFromCSV(csv, ';');
                            break;
                        default:
                            file.status = 500;
                            break;
                    }

                    if (file.status !== 500 && file.status !== 502) {
                        file.status = 200;
                        file.content = `Document Nr. (${idx+1}) ` + file.fileName + ': ' + text;
                    }
                    result.push(file);
                    resolve(file);
                };
                reader.readAsArrayBuffer(file.properties);
            });
        });

        await Promise.all(filePromises);
        const fullPrompt = await extendPromptByFiles(files);
        resolve({ result, fullPrompt });
    });
}