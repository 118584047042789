import { Tooltip } from 'react-tooltip';
import styles from '../../styles/newConversation.module.css'
import AddCircle from "../../../../assets/new_conversation/add_circle.svg";

interface NewConversationProps {
    clearChat: () => Promise<void>
}

function NewConversation({ clearChat }: NewConversationProps) {
    return (
        <>
            <button className={styles.newConversationButton} onClick={clearChat} data-tooltip-id="newConversationTooltip" data-tooltip-content="Deletes the current conversation and starts a new one" data-tooltip-place="top">
                <img
                    className={styles.newConversationIcon}
                    src={AddCircle}
                    alt="Start new conversation"
                />
                New Conversation
            </button>
            <Tooltip id="newConversationTooltip" className={styles.reacttooltip} />
        </>
    )
}

export default NewConversation;