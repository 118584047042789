import { getConfig } from "../../../configProvider/configProvider";

/**
 * Checks if the maximum number of pages has been reached based on the number of words.
 *
 * @param {number} words - The number of words.
 * @return {boolean} Returns true if the maximum number of pages has been reached, otherwise false.
 */
export function maximumPagesReached(words: number): boolean {

    //Average of words on a DINA4 page
    let wordAverage = 400;
    let maximumPages = getConfig().MAXIMUM_DOCUMENT_PAGES;
    let maximumWords = wordAverage *  maximumPages;

    //More then maximum pages
    if (words > maximumWords) {
        return true
    }

    return false
}