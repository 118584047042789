import { Stack } from "@fluentui/react";
import { ErrorCircleRegular } from "@fluentui/react-icons";
import { ChatMessage, Citation, ToolMessageContent } from "../../../api";
import { Answer } from "../../../components/Answer";
import styles from '../Chat.module.css';

interface AnsweProps {
    answer: ChatMessage,
    answers: ChatMessage[],
    index: number,
    onShowCitation: (citation: Citation) => void,
}

/**
 * Parses the citation from a given chat message.
 *
 * @param {ChatMessage} message - The chat message to parse.
 * @return {Array<Citation>} - The parsed citations, if any.
 */
const parseCitationFromMessage = (message: ChatMessage) => {
    if (message.role === "tool") {
        try {
            const toolMessage = JSON.parse(message.content) as ToolMessageContent;
            return toolMessage.citations;
        }
        catch {
            return [];
        }
    }
    return [];
}

function AnswerComponent({ answer, answers, index, onShowCitation }: AnsweProps) {

    let lastUserIndex = -1;
    for (let i = index - 1; i >= 0; i--) {
        if (answers[i].role === "user") {
            lastUserIndex = i;
            break;
        }
    }

    const previousUserAnswer = answers[lastUserIndex];

    return (
        <>
            {answer.role === "user" ? (
                <div className={styles.chatMessageUser} tabIndex={0}>
                    <div className={styles.chatMessageUserMessage}>{answer.content}</div>
                </div>
            ) : (
                answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                    <Answer
                        answer={{
                            answer: answer.content,
                            citations: parseCitationFromMessage(answers[index - 1]),
                        }}
                        onCitationClicked={c => onShowCitation(c)}
                        protocolId={previousUserAnswer.protocolId}
                        protocolize={previousUserAnswer.protocolize}
                    />
                </div> : answer.role === "error" ? <div className={styles.chatMessageError}>
                    <Stack horizontal className={styles.chatMessageErrorContent}>
                        <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                        <span>Error</span>
                    </Stack>
                    <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                </div> : null
            )}
        </>
    )
}

export default AnswerComponent;