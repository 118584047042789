/*
    NEVER: Only token usage and additional information, no personal data
    ANONYM: The participants name is encrypted using a hash
    PERSONAL: Full data of the participants
*/
const ProtocolKinds = {
    NEVER: 'Never',
    ANONYM: 'Anonymized',
    PERSONAL: 'Personal'
};

export default ProtocolKinds;