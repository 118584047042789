/**
 * Extracts text from a CSV string.
 *
 * @param {any} str - The CSV string to extract text from.
 * @param {string} delim - The delimiter used in the CSV string. Default is ','.
 * @return {string} The extracted text from the CSV string.
 */
export const extractTextFromCSV = (str: any, delim = ','): string => {
    str = str.toString();
    const lines = str.split('\n');
    const headers = lines[0].split(delim);
    const data: Record<string, string>[] = [];

    for (let i = 1; i < lines.length; i++) {
        const values = lines[i].split(delim);
        const row: Record<string, string> = {};

        for (let j = 0; j < headers.length; j++) {
            row[headers[j]] = values[j];
        }

        data.push(row);
    }

    const textData: string = data.map(row => {
        return headers.map((header:any) => `${header}: ${row[header]}`).join('\n');
    }).join('\n\n');

    return textData;
}