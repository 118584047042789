import styles from '../Chat.module.css';
import { Stack } from "@fluentui/react";
import Slider from "../../../helper/components/slider/slider";
import { Tooltip } from "react-tooltip";

let sliderValues = ['Never', 'Anonymized', 'Personal']
let sliderTooltips = [
  'No data is recorded.',
  'The participants name is anonymized through encryption',
  'The participants name is retained during the assessment.'
]

interface LoggingProps {
    handleSliderChange: (value: string) => void;
}

function Logging({ handleSliderChange }: LoggingProps) {

    return (
        <div className={styles.protocolColumn}>
          <div className={styles.infoContainer}>
            <div className={styles.infoHeader} data-tooltip-id="loggingInfoTooltip" 
                data-tooltip-content="
                We would like to understand, as part of this PoC, how we can improve the use of AI-powered chatbots at Rhenus. 
                That's why we would like to transcribe the dialogues conducted here. You can choose how it will be logged.
                IMPORTANT: If sensitive data is provided in the chat, please set the logging to 'never'." 
                data-tooltip-place="top">
              <strong> Logging </strong>
            </div>
            <Tooltip id="loggingInfoTooltip" className={styles.reacttooltip} />
            <p className={styles.infoText}>
              
            </p>
          </div>
          <Stack horizontal>
            <Slider values={sliderValues} steps={sliderValues.length - 1} defaultValue="Anonymized" onChange={handleSliderChange} tooltips={sliderTooltips} />
          </Stack>
        </div>
    )
}

export default Logging;