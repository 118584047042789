import { app, authentication } from '@microsoft/teams-js';
import { useEffect } from 'react';
import { UserInfo } from '../../api/models';

const SignIn = () => {

  useEffect(() => {
    let hash = getHashObject();

    if (hash.access_token) {

      let expires_in = parseInt(hash.expires_in);
      let date = new Date(Date.now());
      let expectedState = localStorage.getItem("simple.state");

      if (expectedState !== hash.state) {
        localStorage.setItem("simple.error", JSON.stringify(hash));

        app.initialize().then(_ => {
          authentication.notifyFailure("StateDoesNotMatch");
        });

      }
      else {
        date.setSeconds(date.getSeconds() + expires_in);

        window.location.hash = "";
        
        let userInfo: UserInfo = {
          access_token: hash.access_token,
          expires_on: expires_in.toString(),
        }

        app.initialize().then(_ => {
          authentication.notifySuccess(JSON.stringify(userInfo));
        })
      }
    }
    else if (hash.error) {
      console.log("[ERROR] HASH ERROR: ", hash.error);
    }
    else {
      app.initialize().then(_ => {
        authentication.notifySuccess();
      })
    }
  }, []);

/**
 * Retrieves the hash object from the current window location.
 *
 * @return {object} The hash object containing key-value pairs.
 */
  function getHashObject() {
    let hash = window.location.hash.substring(1);
    let vars = hash.split("&");

    let ret: any = {};

    for (let v of vars) {
      ret[v.split("=")[0]] = decodeURIComponent(v.split("=")[1]);
    }
    return ret;
  }


  return (
    <>
      Redirecting to App...
    </>
  )
}

export default SignIn;