import { Stack } from "@fluentui/react";
import { ShieldLockRegular } from "@fluentui/react-icons";
import styles from '../Chat.module.css';

function Authentication() {
    return (
        <Stack className={styles.chatEmptyState}>
            <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "200px", width: "200px" }} />
            <h1 className={styles.chatEmptyStateTitle}> You are currently not authenticated</h1>
            <h2 className={styles.chatEmptyStateSubtitle}>
                Redirecting to Login...
            </h2>
        </Stack>
    )
}

export default Authentication;