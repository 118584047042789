import { useEffect, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, Stack, Text } from "@fluentui/react";

import styles from "./Answer.module.css";

/* ICONS */
import { ThumbDownIcon, ThumbUpIcon } from "./Icons/icons";
import Comment from "../../assets/chatIcons/comment.svg";
import Copy from "../../assets/chatIcons/copy.svg";
/* ---- */
import { rateProtocol, sendFeedback } from "../../api/protocol/protocol";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'

import Snackbar from "../../helper/components/snackbar/snackbar";
import showSnackbarWithMessage from "../../helper/components/snackbar/snackbarManager/snackbarManager";
import { Tooltip } from "react-tooltip";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    protocolize?: boolean;
    protocolId?: number;
}

export const Answer = ({
    answer,
    onCitationClicked,
    protocolize,
    protocolId
}: Props) => {

    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    /* SNACKBAR */
    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarDuration, setSnackbarDuration] = useState(3000);
    /* COMMENTS */
    const [showCommentInput, setShowCommentInput] = useState(false);
    const [comment, setComment] = useState('');
    const [isCommentSent, setIsCommentSent] = useState(false);
    /* RATING */
    const [isRated, setIsRated] = useState(0);
    const filePathTruncationLimit = 50;
    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);

    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    const rateAnswer = (protocolId: number, liked: boolean) => {
        const rating = liked ? 1 : -1;
        setIsRated(rating);
        rateProtocol(protocolId, rating);
        showSnackbarWithMessage("Thank you for your rating!", 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
    }
    const copyAnswer = (answer: string) => {
        navigator.clipboard.writeText(answer);
        showSnackbarWithMessage("Answer copied!", 3000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
    }
    const handleCommentClick = () => {
        setShowCommentInput(true);
    };

    const handleCommentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setComment(e.target.value);
    };


    const handleCommentSubmit = (protocolId: any) => {
        if (comment.length > 0) {
            setIsCommentSent(true);
            sendFeedback(protocolId, comment);
            showSnackbarWithMessage("Thank you for your feedback!", 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
        }
    };

    const handleCommentClose = () => {
        setShowCommentInput(false);
    };
    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
    }, [isRefAccordionOpen]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <div className={styles.answerContainerWithFeedback}>
                    <Stack.Item grow>
                        <ReactMarkdown
                            linkTarget="_blank"
                            remarkPlugins={[remarkGfm, supersub]}
                            children={parsedAnswer.markdownFormatText}
                            className={styles.answerText}
                        />
                    </Stack.Item>
                    <div className={styles.feedbackSection}>
                        <img
                            src={Copy}
                            className={styles.copy}
                            aria-hidden="true"
                            onClick={() => copyAnswer(answer.answer)}
                        />
                        {protocolize && (
                            <>
                                <ThumbUpIcon
                                    className={styles.thumb}
                                    currentColor={isRated === 1 ? "#28a745" : "#7294c3"}
                                    onClick={() => { rateAnswer(protocolId ?? 0, true); }}
                                />
                                <ThumbDownIcon
                                    className={styles.thumb}
                                    currentColor={isRated === -1 ? "#ed1c16" : "#7294c3"}
                                    onClick={() => { rateAnswer(protocolId ?? 0, false); }}
                                />
                                <img data-tooltip-id="commentTooltip" data-tooltip-content="Add a Note to Your Rating" data-tooltip-place="top"
                                    src={Comment}
                                    className={styles.comment}
                                    aria-hidden="true"
                                    onClick={handleCommentClick}
                                />
                                <Tooltip id="commentTooltip" className={styles.reacttooltip} />
                            </>
                        )}
                    </div>
                </div>
                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>{parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + " references" : "1 reference"}</span>
                                    </Text>
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}
                    <Stack.Item className={styles.answerDisclaimerContainer}>
                        <span className={styles.answerDisclaimer}> </span>
                    </Stack.Item>
                </Stack>
                {chevronIsExpanded &&
                    <div style={{ marginTop: 8, display: "flex", flexFlow: "wrap column", maxHeight: "150px", gap: "4px" }}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }
            </Stack>
            {protocolize && showCommentInput && (
                <div className={styles.commentBubble}>
                    <div className={styles.closeButton} onClick={handleCommentClose}>
                        &times;
                    </div>
                    <input
                        type="text"
                        value={comment}
                        onChange={handleCommentChange}
                        className={styles.commentInput}
                        maxLength={255}
                        placeholder="Your Feedback..."
                        disabled={isCommentSent}
                    />
                    <button
                        onClick={() => handleCommentSubmit(protocolId)}
                        className={styles.commentButton}
                        disabled={isCommentSent}
                    >
                        Senden
                    </button>
                </div>
            )}
            {showSnackbar && (
                <Snackbar message={snackbarMessage} duration={snackbarDuration} onClose={() => setShowSnackbar(false)} />
            )}

        </>
    );
};
