import { UserInfo, ConversationRequest } from "./models";
import { refreshToken } from "./token/token";
import { TeamsApp } from "../services/teams/teamsService";
import { calculateExpireDate } from "../helper/expireDateCalculator/expireDateCalculator";
const teamsAppFunctions = TeamsApp();

/**
 * Retrieves the configuration data from the server.
 *
 * @return {Promise<Object>} The configuration data.
 */
export async function getConfiguration() {
  const response = await fetch("/api/getConfiguration", {
    headers: {
      "Content-Type": "application/json",
    },
  });

  const data = await response.json();
  return data.configuration;
}

/**
 * Sends a conversation API request to the server and returns the response.
 *
 * @param {ConversationRequest} options - The options for the conversation API request.
 * @param {AbortSignal} abortSignal - An optional AbortSignal to cancel the request.
 * @returns {Promise<Response>} - A promise that resolves to the response from the server.
 */
export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal
): Promise<Response> {
  const response = await fetch("/conversation", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      messages: options.messages,
      personal_information: options.extendedPromptByMeEndpoint,
      files_information: options.extendedPromptByFilesEndpoint,
    }),
    signal: abortSignal,
  });

  return response;
}

/**
 * Retrieves user information.
 *
 * @param {boolean} isTeamsApp - Determines if the app is running within Microsoft Teams.
 * @return {Promise<UserInfo[] | undefined>} A promise that resolves to an array of user information or undefined.
 */
export async function getUserInfo(
  isTeamsApp: boolean
): Promise<UserInfo[] | undefined> {
  const response = await fetch("/.auth/me");
  if (!response.ok) {
    if (!isTeamsApp) {
      teamsAppFunctions.redirectToLogin();
    } else {
      try {
        const jsonResult = await teamsAppFunctions.getTokenInTeams();
        const { access_token, expires_on } = JSON.parse(jsonResult);
        if (jsonResult) {
          const updatedUserInfo: UserInfo = {
            access_token,
            expires_on: calculateExpireDate(expires_on),
          };
          const userInfo = await getUserProfile(updatedUserInfo);
          return [userInfo];
        } else {
          console.log("No Access token found!");
        }
      } catch (error) {
        console.error("Error while getting token in Teams:", error);
      }
    }
    return [];
  }

  const payload: UserInfo[] = await response.json();
  return payload;
}

/**
 * Retrieves the user profile from the Microsoft Graph API.
 *
 * @param {UserInfo} currentUserInfo - The information of the current user.
 * @return {Promise<UserInfo>} The updated user profile information.
 */
async function getUserProfile(currentUserInfo: UserInfo) {
  try {
    const response = await fetch(`https://graph.microsoft.com/v1.0/me`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${currentUserInfo.access_token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Error requesting /me: ${response.statusText}`);
    }

    const data = await response.json();
    currentUserInfo.user_id = data.displayName;

    return currentUserInfo;
  } catch (error) {
    throw new Error(`Error requesting /me: ${error}`);
  }
}

/**
 * Checks if the user is authorized to access the resource.
 *
 * @param {UserInfo} userInfo - The user information containing the access token.
 * @return {Promise<boolean>} A promise that resolves to a boolean indicating whether the user is authorized.
 */
export async function isAuthorized(userInfo: UserInfo): Promise<boolean> {
  const response = await fetch("/authorized", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      access_token: userInfo.access_token,
    }),
  });
  
  const data = await response.json();

  if (data.error && data.error.code) {
    const errorCode = data.error.code;

    if (errorCode === "InvalidAuthenticationToken") {
      await refreshToken(userInfo);
    }
  }

  if (!response.ok) {
    return false;
  }

  const groupLength = data.groupLength;

  return groupLength > 0;
}
