import { Protocol } from "../models";

/**
 * Takes a protocol and sends it to the server for logging.
 *
 * @param {Protocol} protocol - The protocol to be logged.
 * @return {Promise<number>} The ID of the last inserted protocol.
 */
export async function takeProtocol(protocol: Protocol) {
  if (!protocol) return [];

  const response = await fetch("/api/protocol/log", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      protocol: protocol,
    }),
  });

  if (!response.ok) {
    return [];
  }

  const data = await response.json();
  const lastInsertedId = data.protocol_id;

  return lastInsertedId;
}

/**
 * Updates the rating for a conversation.
 *
 * @param {number} protocolId - The ID of the protocol.
 * @param {number} rating - The new rating for the protocol.
 * @return {Promise<void>} A promise that resolves when the rating is updated.
 */
export async function rateProtocol(protocolId: number, rating: number) {
  try {
    const response = await fetch(`/api/updateRating?protocolId=${protocolId}&rating=${rating}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) 
      console.log("Rating answer failed");
  } catch (err) {}
}

/**
 * Sends feedback for a conversation.
 *
 * @param {number} protocolId - The ID of the protocol to send feedback for.
 * @param {string} feedback - The feedback message.
 * @return {Promise<void>} - A promise that resolves with no value.
 */
export async function sendFeedback(protocolId: number, feedback: string) {
  try {
    const response = await fetch(`/api/updateFeedback?protocolId=${protocolId}&feedback=${feedback}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) console.log("Sending feedback failed");
  } catch (err) {}
}
