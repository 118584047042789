import { useState } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import NewConversation from "./tools/newConversation/newConversation";
import UploadFile from "./tools/uploadFile/uploadFile";
import FileOverview from "./tools/fileOverview/fileOverview";

interface Props {
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    //New Conversation
    clearChat: () => Promise<void>;
    //Add documents
    files: ExtendedFile[]
    setFiles: (files: ExtendedFile[]) => void;
    //Snackbar
    setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
    snackbarMessage: string;
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
    snackbarDuration: number;
    setSnackbarDuration: React.Dispatch<React.SetStateAction<number>>;
    configurePrompt: (uploadedFiles: ExtendedFile[]) => Promise<ExtendedFile[]>
    isMobileView: boolean,
    setExtendedPromptByFiles: React.Dispatch<React.SetStateAction<string>>;
}

export const QuestionInput = ({ onSend, disabled, placeholder, clearOnSend, clearChat, files, setFiles, setShowSnackbar, snackbarMessage, setSnackbarMessage, snackbarDuration, setSnackbarDuration, configurePrompt, isMobileView, setExtendedPromptByFiles }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
            />

            <div className={styles.upperControlPanelRow}>
                <FileOverview files={files} setFiles={setFiles} isMobileView={isMobileView} setExtendedPromptByFiles={setExtendedPromptByFiles}/>
            </div>

            <div className={styles.controlPanelRow}>

                <div className={styles.controlPanelLeft}>
                    <NewConversation clearChat={clearChat} />
                    <UploadFile files={files} setFiles={setFiles} configurePrompt={configurePrompt} setShowSnackbar={setShowSnackbar} snackbarMessage={snackbarMessage} setSnackbarMessage={setSnackbarMessage} snackbarDuration={snackbarDuration} setSnackbarDuration={setSnackbarDuration} />
                </div>
                <div className={styles.questionInputSendButtonContainer}
                    role="button"
                    tabIndex={0}
                    aria-label="Ask question button"
                    onClick={sendQuestion}
                    onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                >

                    {sendQuestionDisabled ?
                        <SendRegular className={styles.questionInputSendButtonDisabled} />
                        :
                        <img src={Send} className={styles.questionInputSendButton} />
                    }
                </div>
            </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
    );
};
