import { ChangeEvent } from "react";
import { Tooltip } from 'react-tooltip';

import styles from "../../styles/fileUpload.module.css";
import Upload from "../../../../assets/file_upload/upload.svg";
import showSnackbarWithMessage from "../../../../helper/components/snackbar/snackbarManager/snackbarManager";

interface UploadFileProps {
    //Add documents
    files: ExtendedFile[]
    setFiles: (files: ExtendedFile[]) => void;
    //Snackbar
    setShowSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
    snackbarMessage: string;
    setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>;
    snackbarDuration: number;
    setSnackbarDuration: React.Dispatch<React.SetStateAction<number>>;
    configurePrompt: (uploadedFiles: ExtendedFile[]) => Promise<ExtendedFile[]>
}

function UploadFile({ files, setFiles, setShowSnackbar, snackbarMessage, setSnackbarMessage, snackbarDuration, setSnackbarDuration, configurePrompt }: UploadFileProps) {

    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        const newFiles = e.target.files;
        if (newFiles) {
            try {
                if (files.length + newFiles.length > 3) {
                    return showSnackbarWithMessage("You can only upload a maximum of 3 files.", 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);
                }

                const updatedFiles: ExtendedFile[] = Array.from(newFiles).map(file => ({
                    ...file,
                    activated: true,
                    properties: file
                }));

                const allFiles = [...updatedFiles, ...files];
                let resultingFiles: ExtendedFile[] = await configurePrompt(allFiles);
                let validFiles: ExtendedFile[] = [];
                let resultMessage = '';

                resultingFiles.forEach(file => {
                    let shortenedFileName = file.fileName;

                    if (shortenedFileName!.length > 15) {
                        shortenedFileName = shortenedFileName!.substring(0, 15) + '...';
                    }

                    if (file.status === 500)
                        resultMessage += shortenedFileName + " extension is not valid\r\n";

                    if (file.status === 502)
                        resultMessage += shortenedFileName + " is too large (Max. 20 pages)\r\n";

                    if (file.status === 200) {
                        resultMessage += shortenedFileName + " was successfully uploaded\r\n";
                        validFiles.push(file);
                    }
                });

                if (validFiles.length === 0) {
                    resultMessage += "None of the uploaded files were valid.";
                }

                showSnackbarWithMessage(resultMessage, 5000, setSnackbarDuration, setSnackbarMessage, setShowSnackbar);

                setFiles(validFiles);

            } catch (error) {
                console.error("Error handling file:", error);
            }
        }
        /*CLEARING THE CACHE OF FILE INPUT SO IT'S POSSIBLE TO UPLOAD THE SAME FILE AGAIN AFTER DELETING IT*/
        (e.target as HTMLInputElement).value = '';
    };

    return (
        <>
            <div
                className={styles.uploadContainer} title="File Upload"
                data-tooltip-id="fileUploadTooltip" data-tooltip-content="Allowed formats: txt, pdf, docx, xls, xlsx, csv" data-tooltip-place="top"
            >
                <label htmlFor="file-input" className={styles.fileUploadButton}>
                    <img
                        className={styles.fileUploadIcon}
                        src={Upload}
                        alt="Upload Icon"
                    />
                    File
                </label>
                <input
                    type="file"
                    id="file-input"
                    onChange={handleFileChange}
                    multiple={true}
                    accept=".txt, .pdf, .docx, .xls, .xlsx, .csv"
                    style={{ display: 'none' }}
                />
            </div>
            <Tooltip id="fileUploadTooltip" className={styles.reacttooltip} />
        </>
    );

}

export default UploadFile;