import { Outlet } from "react-router-dom";
import styles from "./Layout.module.css";
import { useEffect } from "react";

const Layout = () => {

    useEffect(() => {
    }, []);

    return (
        <div className={styles.layout}>
            <header className={styles.header} role={"banner"}>
                {

                }
            </header>
            <Outlet />
        </div>
    );
};

export default Layout;
