import { UserInfo } from "../models";

/**
 * Refreshes the user's access token if it has expired.
 *
 * @param {UserInfo} userInfo - The user information object.
 * @return {void} - Does not return a value.
 */
export const refreshToken = async (userInfo: UserInfo) => {
    try {
      if (isTokenExpired(userInfo.expires_on)) {
        const response = await fetch("/.auth/refresh", {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userInfo.access_token}`,
          },
        });
  
        if (response.ok) {
          window.location.reload();
        }
  
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  /**
   * Checks if a token has expired based on the expiration time.
   *
   * @param {string} expiresIn - The expiration time of the token.
   * @return {boolean} Returns true if the token has expired, false otherwise.
   */
  const isTokenExpired = (expiresIn: string) => {
    const now = Math.floor(Date.now() / 1000);
    return now >= parseInt(expiresIn);
  };
