import profileStyles from "../styles/profile.module.css";
import LogoutSVG from "../../../assets/profile/logout.svg";
import { logout } from "../../../api/graphUser/graphUser";
interface ProfileProps {
    isMobileView: boolean,
    displayName: string,
    isTeamsApp: boolean;
}
function Profile({ isMobileView, displayName, isTeamsApp }: ProfileProps) {

    return (
        <>
            {!isTeamsApp && (
                <div className={profileStyles.userContainer}>
                    <div className={profileStyles.logout}>
                        <button onClick={logout}>
                            <img src={LogoutSVG} alt="Logout" />
                            {!isMobileView && (
                                <div className={profileStyles.namePlate}>
                                    {displayName}
                                </div>
                            )}
                        </button>
                    </div>
                </div>
            )}
        </>
    )
}

export default Profile;