import { getConfiguration } from "../../api";
import { ConfigProvider } from "../../models/configProvider";

let config: ConfigProvider = {
  AZURE_CLIENT_ID: "",
  AZURE_TENANT_ID: "",
  MAXIMUM_DOCUMENT_PAGES: 0,
  COGNITIVE_SEARCH_STATE: "",
}

export async function initializeConfig(){
  config = await getConfiguration();
}
export function getConfig(): ConfigProvider {
  return config;
}

export function setConfig(config: ConfigProvider): void {
  config = config;
}