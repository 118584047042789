import { GraphUser } from "../models";

const GRAPH_API_ENDPOINT = 'https://graph.microsoft.com/v1.0';

/**
 * Retrieves a user from the Graph API using the provided access token.
 *
 * @param {string} accessToken - The access token used to authenticate the request.
 * @return {Promise<GraphUser>} A promise that resolves to the user data.
 */
export async function getUserFromGraph(accessToken: string): Promise<GraphUser>{
    try {
      const response = await fetch(`${GRAPH_API_ENDPOINT}/me` , {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      
      if (!response.ok) {
        throw new Error(`Error requesting /me: ${response.statusText}`);
      }
  
      const data: GraphUser = await response.json();

      return data;
    } catch (error) {
      throw new Error(`Error requesting  /me: ${error}`);
    }
}

 /**
     * Logs the user out of the current session and redirects to the login/logout page.
     */

 export function logout(){
  const currentUrl = window.location.href;
  const logoutUrl = `/.auth/logout?post_logout_redirect_uri=${encodeURIComponent(currentUrl)}`;
  window.location.href = logoutUrl;
}