import { Stack } from "@fluentui/react";
import { ShieldLockRegular } from "@fluentui/react-icons";
import styles from '../Chat.module.css';

function Authorization() {
    return (
        <Stack className={styles.chatEmptyState}>
            <ShieldLockRegular className={styles.chatIcon} style={{ color: 'darkorange', height: "150px", width: "150px" }} />
            <h1 className={styles.chatEmptyStateTitle}>
                We apologize, but it seems that you do not have the necessary permissions to access this content
            </h1>
        </Stack>
    )
}

export default Authorization;