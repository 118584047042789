import { app, authentication } from "@microsoft/teams-js";
import { getConfig } from "../../helper/configProvider/configProvider";

export const TeamsApp = () => {
  let isTeamsAppInitialized = false;

  function checkIfTeams() {
    return isTeamsAppInitialized;
  }

  /**
   * Retrieves a token in Teams by obtaining user information and performing authentication.
   *
   * @return {Promise<any>} The result of the authentication process.
   */
  async function getTokenInTeams(): Promise<any> {
    const userInformation = await app.getContext();
    const result = await authentication.authenticate({
      url: window.location.origin + "/auth/simple-start",
      width: 600,
      height: 535,
    });
    return result;
  }

  function redirectToLogin() {
    
      const queryParams: { [key: string]: string | number } = {
        client_id: getConfig().AZURE_CLIENT_ID,
        response_type: "code id_token",
        redirect_uri: window.location.origin + "/.auth/login/aad/callback",
        scope: "openid profile email offline_access",
        response_mode: "form_post",
        nonce: 678910,
        state: "redir=/",
      };
  
      const queryString = generateQueryString(queryParams);
  
      const tenantId = getConfig().AZURE_TENANT_ID;
      const authorizationUrl = `https://login.microsoftonline.com/${tenantId}/oauth2/v2.0/authorize?${queryString}`;
  
      window.location.replace(authorizationUrl);
  }
  
/**
 * Generates a query string from an object of parameters.
 *
 * @param {Object} params - The object containing the parameters.
 * @return {string} - The generated query string.
 */
  function generateQueryString(params: { [key: string]: string | number }): string {
      return Object.keys(params)
          .map((key) => `${key}=${encodeURIComponent(params[key])}`)
          .join("&");
  }

  async function initialize() {
      try {
        await app.initialize();
        isTeamsAppInitialized = true;
        app.notifyAppLoaded();
        app.notifySuccess();
      } catch (error) {
        isTeamsAppInitialized = false;
      }
  }

  return {
    checkIfTeams,
    getTokenInTeams,
    redirectToLogin,
    initialize,
  };
};