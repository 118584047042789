import styles from '../../styles/fileOverview.module.css';
import Delete from '../../../../assets/file_overview/delete.svg'
import { ChangeEvent } from 'react';
import { extractTextFromFile } from '../../../../helper/fileExtractor/fileExtractor';
interface FileOverviewProps {
    files: ExtendedFile[]
    setFiles: (files: ExtendedFile[]) => void
    isMobileView: boolean,
    setExtendedPromptByFiles: React.Dispatch<React.SetStateAction<string>>;
}

function FileOverview({ files, setFiles, isMobileView, setExtendedPromptByFiles }: FileOverviewProps) {

    let truncatedName;
    const updateActivationState = async (e: ChangeEvent<HTMLInputElement>, idx: number) => {
        let result: ExtendedFile[] = [];
        let fullPrompt: string;

        const updatedFiles = [...files];
        updatedFiles[idx].activated = e.target.checked;
        setFiles(updatedFiles);
        
        ({ result, fullPrompt } = await extractTextFromFile(updatedFiles));
        setExtendedPromptByFiles(fullPrompt);
    }

    const deleteFileFromList = async (idx: number) => {
        let result: ExtendedFile[] = [];
        let fullPrompt: string;
        
        const updatedFiles = [...files];
        
        updatedFiles.splice(idx, 1);
        setFiles(updatedFiles);

        ({ result, fullPrompt } = await extractTextFromFile(updatedFiles));

        setExtendedPromptByFiles(fullPrompt);
    }

    return (
        <div className={styles.fileOverview}>
            {files.map((file, idx) => {

                if (!isMobileView) {
                    truncatedName = file.properties.name.length > 20
                        ? file.properties.name.slice(0, 20) + '...'
                        : file.properties.name;
                }
                else {
                    truncatedName = file.properties.name.length > 8
                        ? file.properties.name.slice(0, 8) + '...'
                        : file.properties.name;
                }

                return (
                    <div className={styles.file}>
                        <div className={styles.fileName}>
                            {truncatedName}
                        </div>
                        <div className={styles.fileActivation}>
                            <input
                                type="checkbox"
                                checked={file.activated}
                                onChange={(e) => updateActivationState(e, idx)}
                            />
                        </div>
                        <div className={styles.fileDelete}>
                            <img src={Delete} className={styles.deleteIcon} onClick={() => deleteFileFromList(idx)} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

export default FileOverview;