import * as XLSX from 'xlsx';

/**
 * Extracts text from an Excel file using xlsx.
 *
 * @param {ArrayBuffer} arrayBuffer - The ArrayBuffer representation of the Excel file.
 * @return {Promise<string>} The extracted text from the Excel file.
 */
export async function extractTextFromExcel(arrayBuffer: ArrayBuffer): Promise<string> {
    try {
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        let text = '';

        workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const sheetData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });

            for (let i = 0; i < sheetData.length; i++) {
                for (let j = 0; j < sheetData[i].length; j++) {
                    if (sheetData[i][j] !== null && sheetData[i][j] !== undefined) {
                        text += sheetData[i][j].toString() + ' ';
                    }
                }
                text += '\n';
            }
        });

        return text;
    } catch (error) {
        throw error;
    }
}

/**
 * Extracts text from an XLS (Excel 97-2003) file using xlsx.
 *
 * @param {ArrayBuffer} arrayBuffer - The ArrayBuffer representation of the XLS file.
 * @return {Promise<string>} The extracted text from the XLS file.
 */
export async function extractTextFromXLS(arrayBuffer: ArrayBuffer): Promise<string> {
    try {
        const data = new Uint8Array(arrayBuffer);
        const workbook = XLSX.read(data, { type: 'array' });
        let text = '';

        workbook.SheetNames.forEach((sheetName) => {
            const worksheet = workbook.Sheets[sheetName];
            const sheetData: any[] = XLSX.utils.sheet_to_json(worksheet, { header: 1, raw: true });

            for (let i = 0; i < sheetData.length; i++) {
                for (let j = 0; j < sheetData[i].length; j++) {
                    if (sheetData[i][j] !== null && sheetData[i][j] !== undefined) {
                        text += sheetData[i][j].toString() + ' ';
                    }
                }
                text += '\n';
            }
        });

        return text;
    } catch (error) {
        throw error;
    }
}
